<template>
  <div class="page_order_info right_connent">
    <div class="title">订单详情</div>
    <div class="title2">已完成</div>
    <div class="label_wrap">
      <div class="label" v-if="order_info.logistics_id !== 0">快递</div>
    </div>
    <div
      class="item_orderinfo"
      v-for="(item, index) in order_info.order_infos"
      :key="index"
    >
      <div class="name">{{ item.good_name }}</div>
      <div class="end_time">
        过期时间：{{
          item.expire_time == "" ? "永久" : item.expire_time
        }}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量:{{ item.buy_nums }}
      </div>
    </div>
    <div class="old_price">
      原价 <span>￥{{ order_info.order_original_price }}</span>
    </div>
    <div class="old_price">
      优惠
      <span>
        ￥{{
          order_info.order_original_price -
          order_info.order_price
        }}</span
      >
    </div>
    <div class="old_price" v-if="order_info.logistics_id !== 0">
      物流运费 <span>￥{{ order_info.dispatch_price }}</span>
    </div>
    <div class="pay_num">实付 ￥{{ order_info.order_price }}</div>
    <div class="bottom">
      <div class="item">
        下单时间 <span>{{ order_info.create_time }}</span>
      </div>
      <div class="item">
        订单编号<span>{{ order_info.order_no }}</span
        ><input type="text" v-model="order_info.order_no" />
        <div
          class="copy"
          v-clipboard:copy="order_info.order_no"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          复制
        </div>
      </div>

      <div class="item">
        支付方式 <span v-if="order_info.payment_method == 1"> 微信 </span>
        <span v-if="order_info.payment_method == 2"> 支付宝 </span>
        <span v-if="order_info.payment_method == 3"> ios系统 </span>
        <span v-if="order_info.payment_method == 4"> 免费 </span>
      </div>

      <div class="item" v-if="order_info.logistics_id == 1">
        快递单号 <span>{{ order_info.express_num }}</span>
      </div>
      <div class="item" v-if="order_info.logistics_id == 1">
        收件地址
        <p>
          <span
            >{{ order_info.address.consignee }}
            {{ order_info.address.mobile }}</span
          ><br />
          <span
            >{{ order_info.address.region
            }}{{ order_info.address.street }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api";
export default {
  //   name: "my_order",
  data() {
    return {
      order_info: [],
    };
  },
  mounted() {
    this.order_id = this.$route.params.id;
    this.getData(this.$route.params.id);
  },
  methods: {
    onCopy: function (e) {
      this.$notify({
        title: `订单编号：${this.order_info.order_no}`,
        message: "复制成功",
        type: "success",
      });
    },
    onError: function (e) {
      alert("Failed to copy texts");
    },

    async getData(id) {
      try {
        const res = await api.order_info({
          id: id,
        });
        if (res.code == 0) {
          this.order_info = res.result;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {},
};
</script>
<style scoped>
</style>

<style lang="less" scoped>
.right_connent {
  position: relative;
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.page_order_info {
  text-align: left;
  .title {
    padding-bottom: 30px;
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
  }
  .title2 {
    font-size: 20px;
    
    font-weight: 600;
    color: #479dff;
    padding: 25px 0;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
  }
  .label_wrap {
    .label {
      width: 57px;
      height: 24px;
      background: #eff3fd;
      border-radius: 4px;
      font-size: 14px;
      
      font-weight: 400;
      color: #479dff;
      line-height: 24px;
      display: block;
      text-align: center;
      margin-top: 25px;
    }
  }
  .name {
    margin: 10px 0;
    font-size: 16px;
    
    font-weight: 600;
    color: #151515;
  }
  .end_time {
    margin-bottom: 30px;
    font-size: 14px;
    
    font-weight: 400;
    color: #999999;
    line-height: 28px;
  }
  .old_price {
    font-size: 16px;
    
    font-weight: 500;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
    span {
      font-size: 16px;
      
      font-weight: 500;
      color: #1f1f1f;
    }
  }
  .pay_num {
    margin-top: 20px;
    font-size: 16px;
    
    font-weight: 500;
    color: #f96158;
    display: flex;
    justify-content: flex-end;
  }
  .bottom {
    padding-top: 35px;
    .item {
      margin-top: 14px;
      font-size: 16px;
      
      font-weight: 500;
      color: #999999;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      span {
        font-size: 16px;
        
        font-weight: 500;
        color: #1f1f1f;
        margin-left: 20px;
        border: none;
      }
      input {
        opacity: 0;
        width: 0;
      }
    }
    .copy {
      width: 40px;
      height: 20px;
      border: 1px solid #999999;
      border-radius: 10px;
      text-align: center;
      font-size: 12px;
      
      font-weight: 500;
      color: #999999;
      line-height: 20px;
      margin-left: 15px;
      cursor: pointer;
    }
  }
}
</style>